import {
  Autocomplete,
  Badge,
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Compressor from "compressorjs";

import Loader from "../../../components/Loader";

import UploadIcon from "@mui/icons-material/Upload";
import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FormControl from "@mui/material/FormControl";
import FreeSoloCreateOptionDialog from "./AutoCompleteCreateTag";
import React, { useEffect, useMemo, useRef, useState } from "react";
import instance from "../../../utils/axios";
import ErrorIcon from "@mui/icons-material/Error";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "./useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { toast } from "react-toastify";
import { useTheme, useMediaQuery } from "@mui/material";

function CropperImageComponent({
  handleCloseCroppImage,
  openMedias,
  canImportImage,
  imageSrcComponent,
  themeIdComponent,
  tagIdComponent,
  finishCropImage,
}) {
  const [images, setImages] = useState([]);
  console.log("images", images);
  const [blobs, setBlobs] = useState([]);

  const previewCanvasRef = useRef([]);
  const [completedCrop, setCompletedCrop] = useState([]);
  const [loading, setLoading] = useState(false);
  const imgRefs = useRef([]);
  const [buttonValidate, setButtonValidate] = useState(true);
  const [themesCreate, setThemesCreate] = useState([]);
  const [tagsCreate, setTagCreate] = useState([]);
  const [themeId, setThemeId] = useState(themeIdComponent);
  const [tagsId, setTagsId] = useState(tagIdComponent);
  const [error, setError] = useState();
  const [imageSrc, setImageSrc] = useState(imageSrcComponent);
  const [value, setValue] = useState([]);
  const [displayError, setDisplayError] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageDimensions, setImageDimensions] = useState([]);

  // Responsive

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //

  const handleImageSelect = async (e) => {
    if (e.target.files.length + images.length > 5) {
      setError("Vous ne pouvez pas importer plus de 5 images à la fois");
      e.target.files = null;
      return;
    }
    // const maxSizeBeforeCompression = 20 * 1024 * 1024;
    if (e.target.files && e.target.files.length > 0) {
      const newImages = [];
      setError(null);
      for (let i = 0; i < e.target.files.length; i++) {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const img = new Image();
          const maxSizeAfterCompression = 5 * 1024 * 1024; // 5 MB en bytes
          console.log("event", e.target.files[i]);
          const compressedDataUrl = await compressImageURL(event.target.result);
          const compressedBlob = await compressImageUrlToBlob(
            event.target.result
          );
          // const compressedFileSize = compressedDataUrl.length;
          if (compressedBlob.size > maxSizeAfterCompression) {
            setError(
              "La taille de l'image ne peut pas dépasser 5 MB après la compression"
            );
            e.target.value = null; // Limpiar la selección del archivo para que el usuario pueda seleccionar otro archivo
            return;
          }

          if (!compressedDataUrl) {
            setError("Erreur lors de la compression de l'image");
            return;
          }
          console.log("img", img);
          img.src = compressedDataUrl;
          setImageSrc(compressedDataUrl);
          // img.src = event.target.result;
          // setImageSrc(event.target.result);
          console.log("test", compressedDataUrl);
          img.onload = () => {
            imageDimensions.push({
              x: 0,
              y: 0,
              width: img.width,
              height: img.height,
            });
          };
          cropStates.push({ x: 0, y: 0, width: 70, height: 70, unit: "px" });
          const nameSlice = e.target.files[i].name.slice(
            0,
            e.target.files[i].name.lastIndexOf(".")
          );

          newImages.push({
            src: compressedDataUrl,
            name: nameSlice,
            crop: { x: 0, y: 0 },
            zoom: 1,
          });
          if (newImages.length === e.target.files.length) {
            setImages([...images, ...newImages]);
          }
        };
        reader.readAsDataURL(e.target.files[i]);
      }
    }
    setButtonValidate(false);
  };

  const compressImageUrlToBlob = (dataUrl) => {
    return new Promise((resolve, reject) => {
      // Convertir le data URL en Blob
      const blob = dataURLtoBlob(dataUrl);

      // Utiliser Compressor.js avec le Blob
      new Compressor(blob, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(error) {
          // En cas d'erreur, renvoyer le data URL d'origine
          console.error("Erreur lors de la compression de l'image :", error);
          resolve(dataUrl);
        },
      });
    });
  };
  const compressImageURL = (dataUrl) => {
    return new Promise((resolve, reject) => {
      // Convertir le data URL en Blob
      const blob = dataURLtoBlob(dataUrl);

      new Compressor(blob, {
        quality: 0.6,
        success(result) {
          resolve(URL.createObjectURL(result));
        },
        error(error) {
          console.error("Erreur lors de la compression de l'image :", error);
          resolve(dataUrl);
        },
      });
    });
  };
  const compressImageBlob = (blobParam) => {
    return new Promise((resolve, reject) => {
      new Compressor(blobParam, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(error) {
          console.error("Erreur lors de la compression de l'image", error);
          resolve(blobParam);
        },
      });
    });
  };

  const dataURLtoBlob = (dataUrl) => {
    const parts = dataUrl.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uint8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uint8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: contentType });
  };

  const handleCreateFinalData = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      for (let index = 0; index < blobs.length; index++) {
        // const today = Math.floor(new Date().getTime()).toString();
        const mimeType = blobs[index].type;
        const name = images[index].name;
        const maxSizeInBytes = 5 * 1024 * 1024; // 5 Mo
        const compressBlob = await compressImageBlob(blobs[index]);
        if (compressBlob.size <= maxSizeInBytes) {
          formData.append(
            "files",
            compressBlob,
            `${name.replace(/[^a-zA-Z0-9]/g, "")}.${getExtensionFromMimeType(
              mimeType
            )}`
          );
        } else {
          setLoading(false);
          toast.error("Votre image est trop lourde meme après compression", {
            position: "top-right",
            theme: "dark",
          });
          return;
        }
      }
      formData.append("type", "image");
      formData.append("tag", JSON.stringify(tagsId));
      formData.append("themes", JSON.stringify(themeId));

      finishCropImage(formData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  const getExtensionFromMimeType = (mimeType) => {
    const mimeMap = {
      "image/jpeg": "jpg",
      "image/png": "png",
      "image/gif": "gif",
      "image/webp": "webp",
      // Ajoutez d'autres types MIME au besoin
    };

    return mimeMap[mimeType] || "jpg"; // Utilisez 'jpg' par défaut si le type MIME n'est pas trouvé
  };
  const handleSetPreviewCanvas = (canvas, index) => {
    previewCanvasRef.current[index] = canvas;
  };

  const getCroppedImageBlob = async (
    imageCurrentRef,
    previewCanvasRefCurrent,
    completedCropwidth,
    completedCropheight
  ) => {
    return new Promise(async (resolve) => {
      const image = imageCurrentRef;
      const previewCanvas = previewCanvasRefCurrent;
      setError(null);
      if (
        !image ||
        !previewCanvas ||
        !completedCropwidth ||
        !completedCropheight
      ) {
        setError(
          "Erreur, l'image n'a pas été modifiée, veuillez recadrer l'image au moins une fois"
        );
        return;
      }
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const offscreen = new OffscreenCanvas(
        completedCropwidth * scaleX,
        completedCropheight * scaleY
      );
      const ctx = offscreen.getContext("2d");
      if (!ctx) {
        throw new Error("No 2d context");
      }
      ctx.drawImage(
        previewCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height,
        0,
        0,
        offscreen.width,
        offscreen.height
      );

      const blob = await offscreen.convertToBlob({
        type: "image/jpeg",
      });
      resolve(blob);
    });
  };
  const handleSelectTheme = (event, newValue) => {
    if (newValue && newValue.id) {
      setThemeId([newValue.id]);
    } else {
      setThemeId([]);
    }
  };
  const handleSelectTags = (newValue) => {
    let arrayId = [];
    if (newValue && Array.isArray(newValue) && newValue.length > 0) {
      for (const newValueElement of newValue) {
        if (newValueElement) {
          arrayId.push(newValueElement);
        }
      }
      setTagsId(arrayId);
    } else {
      setTagsId([]);
    }
  };
  const handleNextImage = async () => {
    setError(null);
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };
  const valider = async () => {
    const imgRef = imgRefs.current[currentImageIndex];
    const previewCanvasRefData = previewCanvasRef.current[currentImageIndex];
    const completedCropWidth = completedCrop[currentImageIndex]?.width;
    const completedCropHeight = completedCrop[currentImageIndex]?.height;

    const croppedImageBlob = await getCroppedImageBlob(
      imgRef,
      previewCanvasRefData,
      completedCropWidth,
      completedCropHeight
    );
    const updatedBlobs = [...blobs];
    updatedBlobs[currentImageIndex] = croppedImageBlob;
    setBlobs(updatedBlobs);
  };

  const handlePreviousImage = () => {
    setError(null);
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
      setButtonValidate(false);
    }
  };
  const [cropStates, setCropStates] = useState([]);

  const loadData = () => {
    instance
      .get("articles/info-create")
      .then((res) => {
        if (
          res &&
          res.status === 200 &&
          res.data &&
          res.data.tags &&
          res.data.themes
        ) {
          setTagCreate(res.data.tags);
          setThemesCreate(res.data.themes);
        } else {
          setError("Erreur récupération info auteur, theme et tag");
        }
      })
      .catch(() => {
        setError("Erreur récupération info auteur, theme et tag");
      });
  };
  const handleCropChange = (newCrop, imageIndex) => {
    setBlobs((prevBlobs) => {
      const newBlobs = [...prevBlobs];
      newBlobs.splice(currentImageIndex, 1);
      return newBlobs;
    });

    setCropStates((prevCropStates) => {
      const newCropStates = [...prevCropStates];
      newCropStates[imageIndex] = newCrop;
      return newCropStates;
    });
  };
  const handleCompleteCrop = (completedCrop, imageIndex) => {
    setCompletedCrop((prevCropStates) => {
      const newCropStates = [...prevCropStates];
      newCropStates[imageIndex] = completedCrop;
      return newCropStates;
    });
  };
  useDebounceEffect(
    async () => {
      if (
        imgRefs.current[currentImageIndex] &&
        previewCanvasRef.current[currentImageIndex]
      ) {
        canvasPreview(
          imgRefs.current[currentImageIndex],
          previewCanvasRef.current[currentImageIndex],
          completedCrop[currentImageIndex],
          1,
          0
        );
      }
    },
    100,
    [completedCrop, 1, 0, currentImageIndex]
  );
  const handleImageRef = (index) => (ref) => {
    imgRefs.current[index] = ref;
  };
  useEffect(() => {
    previewCanvasRef.current = Array(images.length)
      .fill()
      .map((_, i) => previewCanvasRef.current[i] || null);
  }, [images]);
  useEffect(() => {
    loadData();
  }, []);
  const validateImage = async () => {
    const croppedImageBlob = blobs[currentImageIndex];
    if (croppedImageBlob) {
      setButtonValidate(false);
    } else {
      setButtonValidate(true);
    }
  };

  const isSaveButtonDisabled = useMemo(() => {
    // const isLastImage = currentImageIndex === images.length - 1;
    const isLastImageValidated = blobs.length === images.length;

    return themeId.length === 0 || tagsId.length === 0 || !isLastImageValidated;
  }, [currentImageIndex, images.length, themeId, tagsId, blobs]);

  useEffect(() => {
    validateImage();
  }, [currentImageIndex, blobs, images]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      {loading && <Loader />}
      <Grid
        container
        spacing={1}
        className={isSmallScreen ? "m-0" : "m-4"}
        sx={{ display: isSmallScreen ? "block" : "flex", width: "100%" }}
      >
        {/* <Grid container spacing={1}> */}
        <Grid
          item
          xs={11}
          md={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {canImportImage && (
            <>
              <Input
                type="file"
                onChange={handleImageSelect}
                style={{ display: "none" }}
                id="file-input"
                inputProps={{ multiple: true, accept: "image/*", max: 5 }}
              />
              <label htmlFor="file-input">
                <Button
                  sx={{ mb: 2 }}
                  color="secondary"
                  variant="contained"
                  component="span"
                  startIcon={<UploadIcon />}
                >
                  Importer les Images
                </Button>
                {/* ajouter mini text pour dire que max5 images */}
              </label>
              <Typography
                variant="caption"
                sx={{ color: "black", fontWeight: "bold" }}
              >
                (Maximum 5 images)
              </Typography>
            </>
          )}
          {images.length >= 1 && (
            <div
              style={{
                display: isSmallScreen ? "block" : "flex",
                marginTop: "10px",
                marginBottom: "1rem",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: isSmallScreen ? "flex" : "",
                  height: isSmallScreen ? "35px" : "auto",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handlePreviousImage}
                  disabled={currentImageIndex === 0}
                  sx={{ marginRight: 2 }}
                >
                  Précédent
                </Button>
                {buttonValidate ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={valider}
                    sx={{ marginRight: 2 }}
                    disabled={!buttonValidate}
                  >
                    valider
                  </Button>
                ) : (
                  <IconButton sx={{ backgroundColor: "green" }}>
                    <CheckCircleIcon sx={{ color: "white" }} />
                  </IconButton>
                )}

                {!buttonValidate && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleNextImage}
                    disabled={
                      currentImageIndex === images.length - 1 || buttonValidate
                    }
                    style={{ marginLeft: "10px" }}
                  >
                    Suivant
                  </Button>
                )}
              </div>

              <div
                className={`  ${
                  isSmallScreen ? "mt-4 ml-[0.5rem]" : "ml-[15rem]"
                }`}
              >
                <Typography
                  variant="caption"
                  sx={{ color: "black", fontWeight: "bold" }}
                >
                  Nombre d'images validées
                </Typography>

                <Badge
                  badgeContent={`${blobs.length}/${images.length}`}
                  sx={{ ml: "0.5rem" }}
                  color="primary"
                >
                  <IconButton
                    sx={{
                      backgroundColor:
                        blobs.length === images.length ? "#0077cc" : "red",
                    }}
                  >
                    <CheckCircleIcon sx={{ color: "white" }} />
                  </IconButton>
                </Badge>
              </div>
            </div>
          )}
          {error && (
            <div
              className="error-container"
              style={{
                background: "#ffe2e2",
                padding: "3px",
                borderRadius: "5px",
                border: "1px solid #ff5959",
                display: error ? "flex" : "none",
              }}
            >
              <ErrorIcon
                size={10}
                style={{
                  marginRight: "10px",
                  marginTop: "5px",
                  color: "#ff5959",
                }}
              />
              <span
                className="mt-1"
                style={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  color: "#ff5959",
                }}
              >
                {error}
              </span>
            </div>
          )}
          <ReactCrop
            scale={1}
            aspect={16 / 9}
            style={{ transform: `scale(1) rotate(0deg)` }}
            crop={cropStates[currentImageIndex]}
            onComplete={(completedCrop) =>
              handleCompleteCrop(completedCrop, currentImageIndex)
            }
            onChange={(newCrop) => handleCropChange(newCrop, currentImageIndex)}
          >
            {images.map((image, index) => {
              const imagesize = imageDimensions[index]?.height;
              const isImageSuperHigh = imagesize > 600 ? 600 : imagesize;
              return (
                <div
                  key={index}
                  style={{
                    display: index === currentImageIndex ? "block" : "none",
                    maxHeight: 700,
                    height: isSmallScreen
                      ? isImageSuperHigh > 186
                        ? 186
                        : isImageSuperHigh
                      : isImageSuperHigh,
                  }}
                >
                  <img
                    ref={handleImageRef(index)}
                    src={image.src}
                    alt="Crop"
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                  />
                </div>
              );
            })}
          </ReactCrop>
          {/* </div> */}
          {images.length > 0 && (
            <div className="mt-7">
              <Typography variant="h6" sx={{ mb: 2 }}>
                Prévisualisation
              </Typography>
              <canvas
                ref={(canvas) =>
                  handleSetPreviewCanvas(canvas, currentImageIndex)
                }
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: completedCrop[currentImageIndex]?.width,
                  height: completedCrop[currentImageIndex]?.height,
                }}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={10} md={4}>
          <Paper variant="outlined" sx={{ m: 1, p: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {/* <Box>
                                <Button sx={{ backgroundColor: (theme) => theme.palette.secondary.main, mr: 2 }}>
                                    <PrintIcon style={{
                                        color: "white",
                                    }}
                                    />
                                </Button>
                                <Button sx={{ backgroundColor: (theme) => theme.palette.secondary.main }}>
                                    <PictureAsPdfIcon style={{
                                        color: "white",
                                    }}
                                    />
                                </Button>
                            </Box> */}
              {!openMedias && (
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ fontWeight: "bold" }}
                >
                  {" "}
                  Bibliothèque
                </Button>
              )}
            </Box>
            <FormControl sx={{ mt: 3 }}></FormControl>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 4,
                paddingLeft: 0,
                paddingBottom: 0,
                paddingTop: 0,
              }}
            >
              <label style={{ minWidth: "70px" }} htmlFor="number-input-height">
                Theme :{" "}
              </label>
              <Autocomplete
                disablePortal
                size="small"
                id="combo-demo"
                sx={{ width: "100%" }}
                onChange={handleSelectTheme}
                options={themesCreate}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField size="small" {...params} label="" />
                )}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 4,
                paddingLeft: 0,
                paddingBottom: 0,
                paddingTop: 0,
              }}
            >
              <label style={{ minWidth: "70px" }} htmlFor="number-input-height">
                Tags :{" "}
              </label>
              <FreeSoloCreateOptionDialog
                listTag={tagsCreate}
                getInputValue={handleSelectTags}
                reloadPage={loadData}
                value={value}
                setValue={setValue}
                setTagCreate={setTagCreate}
                tagsId={tagsId}
                setTagsId={setTagsId}
              />
            </div>
            {displayError && (
              <div
                className="error-container"
                style={{
                  background: "#ffe2e2",
                  padding: "3px",
                  borderRadius: "5px",
                  border: "1px solid #ff5959",
                  display: displayError ? "flex" : "none",
                }}
              >
                <ErrorIcon
                  size={10}
                  style={{
                    marginRight: "10px",
                    marginTop: "5px",
                    color: "#ff5959",
                  }}
                />
                <span
                  className="mt-1"
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    color: "#ff5959",
                  }}
                >
                  Veuillez sélectionner au moins un thème et une tag. Ces
                  informations sont nécessaires pour continuer.
                </span>
              </div>
            )}

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                mt: 3,
              }}
            >
              <Button
                color="secondary"
                variant="contained"
                sx={{ fontWeight: "bold" }}
                onClick={handleCreateFinalData}
                disabled={isSaveButtonDisabled}
              >
                {" "}
                Enregistrer
              </Button>
              {openMedias && (
                <Button
                  color="error"
                  variant="contained"
                  sx={{ fontWeight: "bold", ml: 2 }}
                  onClick={handleCloseCroppImage}
                >
                  {" "}
                  Annuler
                </Button>
              )}
            </Box>
          </Paper>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              backgroundColor: "#E5D7CF",
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Instructions
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              - Le <strong>recadrage d'images</strong> vous permet de
              sélectionner une partie spécifique d'une image. Après avoir
              importé une image, vous pouvez ajuster le cadre de recadrage pour
              sélectionner la partie souhaitée de l'image. Pour{" "}
              <strong>valider</strong> l'image actuelle et passer à la suivante,
              appuyez sur le bouton "Valider". Vous ne pourrez pas passer à
              l'image suivante tant que l'image actuelle n'aura pas été validée.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              - Si vous modifiez à nouveau l'image après l'avoir validée, vous
              devrez à nouveau la <strong>valider</strong> avant de passer à
              l'image suivante. Assurez-vous de <strong>valider</strong> chaque
              modification apportée à l'image avant de passer à la suivante.
            </Typography>
            <Typography variant="body1">
              - Vous pouvez utiliser la <strong>prévisualisation</strong> pour
              voir à quoi ressemblera l'image finale avant de la valider.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

CropperImageComponent.defaultPropos = {
  canImportImage: true,
  imageSrcComponent: null,
  themeIdComponent: [],
  tagIdComponent: [],
  finishCropImage: () => {},
};

export default CropperImageComponent;
