const formateDate = (date) => {
    const dateObject = new Date(date);
    const options = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
    };
    let localDateString = dateObject.toLocaleDateString("fr-FR", options);
    localDateString = localDateString.replace(
        /(\w)(\w*)/g,
        function (g0, g1, g2) {
            return g1.toUpperCase() + g2.toLowerCase();
        }
    );
    return localDateString;
};

export default formateDate;