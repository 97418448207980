import React from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import formateDate from "../../utils/fomateDate";
import { IMAGE_URL } from "../../constants/url";
import logo from '../../assets/Logo MARITIMA.png';

const ContentList = ({ items, type }) => {
    return items.map((item, index) => {
        const isArticle = type === 'article';
        const isPodcast = type === 'podcast';
        const isTv = type === 'tv';

        return (
            <React.Fragment key={index}>
                <h6 className="text-[#333C5B] pb-2 ml-4">
                    {formateDate(item.createdAt)}
                </h6>
                <Link
                    to={`/${isArticle ? 'articles' : isPodcast ? 'podcast' : 'tv'}${isArticle ? "" : "/edit/"}${isPodcast ? item.podcast_show_id : isArticle ? "" : item.tv_show_id}/${item.id}`}
                    style={{ textDecoration: "none" }}
                    className={"group cursor-pointer"}>
                    <div className={"flex flex-col ml-4"}>
                        <div className='flex justify-content-between'>
                            <div className={"flex flex-row items-center"}>
                                <div
                                    className={
                                        "rounded-[10px] flex flex-col items-center justify-center gap-3 transform hover:scale-[1.03] transition-transform duration-200 cursor-pointer"
                                    }
                                ></div>
                                <div className={"flex flex-col"}>
                                    <div className={"flex flex-row gap-2"}>
                                        <img
                                            style={{
                                                objectFit: "cover",
                                                height: "100px",
                                                width: "150px",
                                                ...(isTv && { backgroundColor: "#16324D", padding: 6, height: "125px", width: "120px", marginTop: 8, marginLeft: 15, marginRight: 5 })
                                            }}
                                            alt={item.title}
                                            className="rounded-lg"
                                            src={isTv ? logo : `${IMAGE_URL}/${isArticle ? item.medias[0].url : item.media.url}`}
                                        >
                                        </img>
                                        <div className={"flex flex-col"}>
                                            <p className="text-[#333C5B] group-hover:text-[#05a9f4] ease-out duration-500 leading-trim-both text-edge-cap font-nums-tabular font-massilia font-[500] text-[20px] line-clamp-2">
                                                {item?.title}
                                            </p>
                                            <p className={"text-[13px] line-clamp-2  text-[#333C5B]"}>{item?.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-content-end'>
                                <div className='flex'>
                                    <div className='ml-9'>
                                        <Tooltip title={item.is_active ? "Actif" : "Desactive"}>
                                            <CheckCircleOutlineIcon
                                                style={{
                                                    color: item.is_active ? "green" : "black",
                                                }}
                                                sx={{
                                                    fontSize: 25,
                                                    marginRight: "10px"
                                                }}
                                            />
                                        </Tooltip>
                                    </div>

                                    <Link to={`/${isArticle ? 'articles' : isPodcast ? 'podcast' : 'tv'}${isArticle ? "" : "/edit/"}${isPodcast ? item.podcast_show_id : isArticle ? "" : item.tv_show_id}/${item.id}`} >
                                        <button>
                                            <EditIcon
                                                style={{
                                                    color: "black",
                                                }}
                                                sx={{ fontSize: 25 }}
                                            />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <hr className={"bg-[rgba(22,50,77,0.50)] h-[1px] w-full my-4"} />
                    </div>
                </Link>
            </React.Fragment>
        );
    });
}

export default ContentList;
