import React from "react";
import Button from "@mui/material/Button";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

//refacto with comfirmDeleteModal

const AlertDialog = ({ open, handleClose, handleAgree, title, label, buttonAccept, buttonCancel, loaderNotification }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
          {title ? title : "Confirmer la suppression"}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
          {label ? label : "Êtes-vous sûr de vouloir supprimer cet élément ?"}
        </Typography>
          {loaderNotification ?
              <Box sx={{ display: 'flex', width : '100%', justifyContent: 'center' }}>
                <CircularProgress />
              </Box> : (
            <>
                <Button onClick={handleClose} color={buttonCancel ? "error" : "primary"} variant="contained" sx={{ mr: 2 }}>
                    {buttonCancel ? buttonCancel : "Annuler"}
                </Button>
                <Button onClick={handleAgree} color={buttonAccept ? "success" : "error"} variant="contained">
                    {buttonAccept ? buttonAccept : "Supprimer"}
                </Button>
            </>
              )
        }
      </Box>
    </Modal>
  );
};



export default AlertDialog;
