import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
import mediaService from "../../../services/media.service";
import { IMAGE_URL } from "../../../constants/url";
import { useTheme, useMediaQuery } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalMedia = ({
  open,
  handleClose,
  setDataArticle,
  dataArticle,
  infoCreate,
  setInfoCreate,
  isPodcast = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [order, setOrder] = useState("desc");

  // Responsive
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //

  const handleTagChange = (event) => {
    setSelectedTags(event.target.value);
  };

  const handleThemeChange = (event) => {
    setSelectedThemes(event.target.value);
  };

  const handleImageUpload = async (file) => {
    mediaService
      .uploadMedia(file)
      .then((response) => {
        if (response.data) {
          setInfoCreate({
            ...infoCreate,
            media: [...infoCreate.media, response.data],
          });
          toast.success("Image ajoutée avec succès", {
            position: "top-right",
            theme: "dark",
          });
        } else {
          toast.error("Error lors du chargement des images", {
            position: "top-right",
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error lors du chargement des images", {
          position: "top-right",
          theme: "dark",
        });
      });
  };
  const filteredMediaWithoutThemeOrTag = infoCreate?.media.filter(
    (item) => item?.tags?.length > 0 || item?.themes?.length > 0
  );
  const filteredMedia = filteredMediaWithoutThemeOrTag
    .filter((media) => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      if (
        searchTerm === "" &&
        selectedTags?.length === 0 &&
        selectedThemes?.length === 0
      ) {
        return true;
      }
      if (
        media.title &&
        media.title.toLowerCase().includes(lowerCaseSearchTerm)
      ) {
        return true;
      }
      if (selectedTags.length > 0) {
        for (const tag of media.tags) {
          if (selectedTags.includes(tag.name)) {
            return true;
          }
        }
      }
      if (selectedThemes.length > 0) {
        for (const theme of media.themes) {
          if (selectedThemes.includes(theme.name)) {
            return true;
          }
        }
      }
      return false;
    })
    .sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return order === "desc" ? dateB - dateA : dateA - dateB;
    });
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    console.log(file);
    handleImageUpload(file);
  };
  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Media
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            Sauvegarder
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          mt: 5,
          mx: isSmallScreen ? 1 : 3,
          position: "relative",
          display: "flex",
        }}
      >
        <FormControl fullWidth sx={{ mb: 2, mr: 2 }}>
          <InputLabel id="tags-select-label">Tags</InputLabel>
          <Select
            style={{ zIndex: 999999 }}
            labelId="tags-select-label"
            multiple
            value={selectedTags}
            onChange={handleTagChange}
            label="Tags"
          >
            {infoCreate?.tags.map((tag) => (
              <MenuItem key={tag.id} value={tag.name}>
                {tag.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2, mr: isSmallScreen ? 0 : 2 }}>
          <InputLabel id="themes-select-label">Themes</InputLabel>
          <Select
            labelId="themes-select-label"
            multiple
            value={selectedThemes}
            onChange={handleThemeChange}
            label="Themes"
          >
            {infoCreate?.themes.map((theme) => (
              <MenuItem key={theme.id} value={theme.name}>
                {theme.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ ml: 2 }}>
          <InputLabel id="order-select-label">Ordre</InputLabel>
          <Select
            labelId="order-select-label"
            value={order}
            onChange={(e) => setOrder(e.target.value)}
            label="Ordre"
          >
            <MenuItem value="desc">
              {" "}
              {isSmallScreen ? "Récents" : "Les plus récents d'abord"}
            </MenuItem>
            <MenuItem value="asc">
              {" "}
              {isSmallScreen ? "Anciens" : "Les plus anciens d'abord "}
            </MenuItem>
          </Select>
        </FormControl>

        {!isSmallScreen && (
          <Button
            // variant="outlined"
            component="label"
            style={{
              // border: "2px dashed black",
              // borderRadius: "10px",
              marginLeft: 12,
              width: "60%",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
            disabled={true}
          ></Button>
        )}
      </Box>
      <ImageList
        sx={{ width: "100%", height: "100%" }}
        cols={isSmallScreen ? 2 : 5}
        gap={1}
      >
        {filteredMedia.map((media, index) => {
          return (
            <ImageListItem
              key={index}
              style={{
                cursor: "pointer",
                height: isSmallScreen ? "150px" : "300px",
              }}
              cols={1}
              onClick={() => {
                const mediaId = media.id;
                if (dataArticle.media_id.includes(mediaId)) {
                  setDataArticle({
                    ...dataArticle,
                    media_id: dataArticle.media_id.filter(
                      (id) => id !== mediaId
                    ),
                  });
                } else if (isPodcast && dataArticle.media_id.length === 1) {
                  toast.error(
                    "Vous ne pouvez pas ajouter plus d'une image pour un podcast",
                    {
                      position: "top-right",
                      theme: "dark",
                    }
                  );
                } else {
                  setDataArticle({
                    ...dataArticle,
                    media_id: [...dataArticle.media_id, mediaId],
                  });
                }
              }}
            >
              <img
                alt=""
                src={
                  media.type === "image"
                    ? `${IMAGE_URL}${media.url}?w=248&fit=crop&auto=format`
                    : `http://img.youtube.com/vi/${media.url}/maxresdefault.jpg`
                }
                srcSet={
                  media.type === "image"
                    ? `${IMAGE_URL}${media.url}?w=248&fit=crop&auto=format&dpr=2 2x`
                    : `http://img.youtube.com/vi/${media.url}/maxresdefault.jpg`
                }
                loading="lazy"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              {dataArticle.media_id.includes(media.id) && (
                <ImageListItemBar
                  title={`${dataArticle.media_id.indexOf(media.id) + 1} / ${
                    dataArticle.media_id.length
                  }`}
                  actionIcon={
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      aria-label="info about"
                    >
                      <CheckBoxIcon
                        style={{
                          color: "green",
                          backgroundColor: "white",
                          border: "1px solid white",
                          borderRadius: "5%",
                        }}
                      />
                    </IconButton>
                  }
                />
              )}
            </ImageListItem>
          );
        })}
      </ImageList>
    </Dialog>
  );
};
