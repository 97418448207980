export const IMAGE_URL =
  process.env.REACT_APP_NODE_ENV === "production"
    ? "https://ws.maritima.fr/api/"
    : process.env.REACT_APP_NODE_ENV === "staging"
    ? "https://maritima.digital-paca.fr/api/"
    : "https://maritima.digital-paca.fr/api/";
export const IMAGE_URL_WYSIWYG =
  process.env.REACT_APP_NODE_ENV === "production"
    ? "https://ws.maritima.fr/api/"
    : process.env.REACT_APP_NODE_ENV === "staging"
    ? "https://maritima.digital-paca.fr/api/"
    : "https://maritima.digital-paca.fr/api/";
export const API_URL =
  process.env.REACT_APP_NODE_ENV === "production"
    ? "https://ws.maritima.fr/api_bo/"
    : process.env.REACT_APP_NODE_ENV === "staging"
    ? "https://maritima.digital-paca.fr/api_bo/"
    : "https://maritima.digital-paca.fr/api_bo/";
export const MARITIMMA_NEWSLETTER =
  process.env.REACT_APP_NODE_ENV === "production"
    ? "https://maritima.fr/"
    : process.env.REACT_APP_NODE_ENV === "staging"
    ? "https://maritima.digital-paca.fr/"
    : "https://maritima.digital-paca.fr/";
