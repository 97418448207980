import React, { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import AlertDialog from "./Alert";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import articlesService from "../services/articles.service";
import CircularProgress from "@mui/material/CircularProgress";


export default function Action({ deleteAction, editLink, label, notificationActivate = false, editActivate = false, trashIcon = false, idArticle = undefined, setRows, notifications, rows, isAgenda, handleOpenConfirmNotification }) {
    const [openDialog, setOpenDialog] = useState(false);
    const [loader, setLoader] = useState(false);
    const handleDeleteClick = () => {
        setOpenDialog(true);
    };
    const handleDeleteConfirmed = () => {
        deleteAction(idArticle);
        setOpenDialog(false);
    };

    const handleDeleteCancelled = () => {
        setOpenDialog(false);
    };

    const verifyNotificaionsIsnotUndefined = notifications !== undefined ? notifications : 0;
    const notificationActivated = notifications === 0 ? false : true;

    return (
        <div>

            <AlertDialog
                open={openDialog}
                handleClose={handleDeleteCancelled}
                handleAgree={handleDeleteConfirmed}
                label={label}
            />



            {editActivate &&
                <Button
                    style={{
                        backgroundColor: "#0057BA",
                        minWidth: 0,
                        marginRight: 5
                    }}
                    size="medium"
                >

                    <Link to={editLink} style={{ lineHeight: 0 }}>
                        <EditIcon
                            style={{
                                color: "white",
                            }}
                            sx={{ fontSize: 25 }}
                        />
                    </Link>

                </Button>
            }
            {notificationActivate &&
                <>
                    {loader ?

                        <CircularProgress style={
                            {
                                marginLeft: 5,
                            }
                        } size={25} />
                        :
                        <Button
                            onClick={() => handleOpenConfirmNotification(idArticle)}
                            style={{
                                backgroundColor: notificationActivated ? "#ff5f5f" : "#60E196",
                                minWidth: 0
                            }}
                            size="medium"
                        >

                            <NotificationsIcon
                                style={{
                                    color: "white",
                                }}
                                sx={{ fontSize: 25 }}
                            />
                            {notificationActivated &&
                                <>
                                    <CircleIcon
                                        style={{
                                            color: "white",
                                            position: "absolute",
                                            marginLeft: 30,
                                            marginTop: -25,
                                        }}
                                        sx={{ fontSize: 30 }}
                                    />

                                    <CircleOutlinedIcon
                                        style={{
                                            color: "red",
                                            position: "absolute",
                                            marginLeft: 30,
                                            marginTop: -25,

                                        }}
                                        sx={{ fontSize: 30 }}
                                    />
                                    <span style={
                                        {
                                            color: "red",
                                            position: "absolute",
                                            marginLeft: 30,
                                            marginTop: -26
                                        }
                                    }> {verifyNotificaionsIsnotUndefined}</span>
                                </>
                            }



                        </Button>
                    }
                </>
            }

            {trashIcon &&
                <Button
                    onClick={handleDeleteClick}
                    style={{
                        // backgroundColor: "#60E196",
                        minWidth: 0
                    }}
                    size="medium"
                >
                    <DeleteIcon
                        style={{
                            color: "grey",
                        }}
                        fontSize="small"
                    />
                </Button>
            }

        </div>
    );
}
