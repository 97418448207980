import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import articlesService from "../../services/articles.service";
import Box from '@mui/material/Box';
import { Badge } from '@mui/material';
import ContentList from './ContentList';

const Search = () => {
    const [searchText, setSearchText] = useState('');
    const [articles, setArticles] = useState([]);
    const [podcasts, setPodcasts] = useState([]);
    const [tv, setTv] = useState([]);
    const [selectedOption, setSelectedOption] = useState(1);
    const [debouncedText, setDebouncedText] = useState(searchText);

    const handleOptionChange = (event) => {
        setSelectedOption(parseInt(event.target.value));
    };
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedText(searchText);
        }, 500); // 500 ms delay

        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    useEffect(() => {
        if (debouncedText) {
            handleSearch(debouncedText);
        }
    }, [debouncedText]);

    const handleSearch = async (text) => {
        try {
            await articlesService.searchContent(text).then((response) => {
                const result = response.data.data
                if (result && result[0] && result[2] && result[3]) {
                    setArticles(result[0]);
                    setTv(result[2]);
                    setPodcasts(result[3]);
                }
            })

        } catch (error) {
            console.error('Error searching articles:', error);
        }
    };
    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const renderCategory = (id) => {
        switch (id) {
            case 1:
                return <ContentList items={articles} type="article" />;
            case 2:
                return <ContentList items={podcasts} type="podcast" />;
            case 3:
                return <ContentList items={tv} type="tv" />;
            default:
                break;
        }
    };

    return (
        <div>
            <div className="flex flex-col gap-4">
                <TextField
                    label="Rechercher"
                    id="outlined-start-adornment"
                    sx={{ width: "70ch", backgroundColor: "#F8F8F8" }}
                    value={searchText}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <div className={"container-filter flex flex-row gap-5"}>
                    <div className={"border border-gray-200 p-1 rounded-lg"}>
                        <div className="radio-buttons flex gap-1 text-uppercase text-[14px]">
                            <div>
                                <input type="radio" id="first-1" className="first hidden" name="section-1" value="1" checked={selectedOption === 1} onChange={handleOptionChange} />
                                <label htmlFor="first-1" className={`flex items-center gap-2 cursor-pointer p-2 rounded-lg ${selectedOption === 1 ? "bg-blue-500 text-white" : "bg-gray-100"}`}>

                                        <span className={`${selectedOption === 1 ? "font-bold" : "font-normal"}`}>Article    <Badge
                                            color="secondary"
                                            style={{
                                                marginTop: "-35px",
                                            }}
                                            badgeContent={articles.length}
                                            classes={{
                                                badge: "bg-red-500",
                                            }}
                                        /></span>

                                </label>
                            </div>
                            <div>
                                <input type="radio" id="second-1" className="second hidden" name="section-1" value="2" checked={selectedOption === 2} onChange={handleOptionChange} />
                                <label htmlFor="second-1" className={`flex items-center gap-2 cursor-pointer p-2 rounded-lg ${selectedOption === 2 ? "bg-blue-500 text-white" : "bg-gray-100"}`}>

                                        <span className={`${selectedOption === 2 ? "font-bold" : "font-normal"}`}>Podcast
                                             <Badge
                                                 color="secondary"
                                                 style={{
                                                     marginTop: "-35px",
                                                 }}
                                                 badgeContent={podcasts.length}
                                                 classes={{
                                                     badge: "bg-red-500",
                                                 }}
                                             />
                                        </span>

                                </label>
                            </div>
                            <div>
                                <input type="radio" id="third-1" className="third hidden" name="section-1" value="3" checked={selectedOption === 3} onChange={handleOptionChange} />
                                <label htmlFor="third-1" className={`flex items-center gap-2 cursor-pointer p-2 rounded-lg ${selectedOption === 3 ? "bg-blue-500 text-white" : "bg-gray-100"}`}>

                                        <span className={`${selectedOption === 3 ? "font-bold" : "font-normal"}`}>TV    <Badge
                                            color="secondary"
                                            style={{
                                                marginTop: "-35px",
                                            }}
                                            badgeContent={tv.length}
                                            classes={{
                                                badge: "bg-red-500",
                                            }}
                                        /></span>

                                </label>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Box sx={{ marginTop: '2rem', maxWidth: "1240px" }}>
                <div className="w-full md:w-[80%]">{renderCategory(selectedOption)}</div>
            </Box>

        </div>
    );
};

export default Search;

