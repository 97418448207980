import instance from "../utils/axios";
import { API_URL } from "../constants/url";
class articles {
  constructor() {
    this.instance = instance;
  }
  // getArticles = async (setRows, setLoader) => {
  //   const token = localStorage.getItem("jwt");
  //   const options = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       ...(token && { Authorization: `Bearer ${token}` }),
  //     },
  //   };
  //   try {
  //     const response = await fetch(`${API_URL}articles/all`, options);
  //     if (!response.ok) {
  //       throw new Error("Erreur lors de la récupération du flux de données");
  //     }
  //     const reader = response.body.getReader();
  //     const decoder = new TextDecoder("utf-8");

  //     let done = false;
  //     let chunkedText = "";

  //     while (!done) {
  //       const { value, done: readerDone } = await reader.read();
  //       done = readerDone;

  //       if (value) {
  //         const chunk = decoder.decode(value, { stream: true });
  //         chunkedText += chunk;
  //         try {
  //           const jsonObjects = chunkedText.split("\n");
  //           jsonObjects.forEach((json) => {
  //             if (json.trim()) {
  //               try {
  //                 const jsonData = JSON.parse(json);
  //                 chunkedText.replace(json, "");
  //                 let reverseData = jsonData.reverse();
  //                 setRows(reverseData);
  //                 setLoader(false);
  //               } catch (e) {}
  //             }
  //           });
  //         } catch (e) {
  //           //console.log(chunkedText)
  //         }
  //       }
  //     }

  //     console.log("Flux terminé");
  //   } catch (error) {
  //     console.error("Erreur:", error);
  //   }
  // };

  getArticles = async () => {
    return await instance.get("articles/all", {
      withCredentials: true,
    });
  };

  getArticle = async (id) => {
    return await instance.get(`articles/one?id=${id}`, {
      withCredentials: true,
    });
  };

  createArticle = async (data) => {
    return await instance.post(
      "articles/create",
      data
      // {
      //   title: data.title,
      //   description: data.description,
      //   body: data.body,
      //   publication_date: data.publication_date,
      //   is_active: data.is_active,
      //   city_id: data.city_id,
      //   author_id: data.author_id,
      //   theme_id: data.theme_id,
      //   tag_id: data.tag_id,
      //   media_id: data.media_id,
      // }
    );
  };

  updateArticle = async (values) => {
    return await instance.put("articles/update/", values, {
      withCredentials: true,
    });
  };
  getNotificationsById = async (id) => {
    return await instance.get(`articles/send-notification?id=${id}`, {
      withCredentials: true,
    });
  };

  deleteArticle = async (id) => {
    return await instance.delete(`articles/delete?id=${id}`, {
      withCredentials: true,
    });
  };

  searchContent = async (str) => {
    return await instance.post(
      `/articles/search`,
      { text: str },
      {
        withCredentials: true,
      }
    );
  };
}

var articlesService = new articles();
export default articlesService;
