import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ListItemText from '@mui/material/ListItemText';
import { Divider } from '@mui/material';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { useTheme, useMediaQuery } from "@mui/material";



const AdminStats = ({
    artCounter,
    topCities,
    themeCounter,
    artTheme,
    flopCities,
    flopTheme
}) => {
    // Responsive 
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box sx={{ padding: 0 }}>

            <Box sx={{ display: isSmallScreen ? "block" : "flex" }}>

                <Box
                    sx={{
                        display: "flex",
                        backgroundColor: "#FBFBFB",
                        width: 300,
                        minHeight: 20,
                        marginLeft: isSmallScreen ? "" : "3rem",
                        marginTop: 5,
                        flexDirection: "column",
                        borderRadius: 2,
                        maxHeight: 200,
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.3)"
                    }}
                >

                    <div
                        style={{
                            backgroundColor: "#0057BA",
                            padding: "8px 16px 0px",
                            color: "white",
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5,

                        }}
                    >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <p className="font-bold" style={{ margin: 0, fontSize: 13 }}>ARTICLES LES PLUS LUS</p>
                            <NewspaperIcon style={{ marginLeft: 15 }} />
                        </div>
                        <Divider sx={{ backgroundColor: "#fff", margin: "5px 0px 4px" }} />
                    </div>

                    <Box
                        sx={{
                            overflowY: "scroll",
                            WebkitOverflowScrolling: "touch",
                            padding: 2,
                            "&::-webkit-scrollbar": {
                                width: "10px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#16324dc1",
                                borderRadius: "6px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "#999",
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#f5f5f5",
                                border: "0px solid #ccc",
                                borderRadius: "8px",
                            },
                        }}
                    >
                        {artCounter.map((articles, articleIndex) => (
                            <Box key={articleIndex} sx={{ display: "flex" }}>

                                <ListItemText
                                    primary={articles?.title ? (articles.title.length > 50 ? articles.title.slice(0, 50) + "..." : articles.title) : ""}
                                    primaryTypographyProps={{
                                        fontSize: 13,
                                        fontWeight: 600,
                                        color: "#021E40",

                                    }}
                                    sx={{ width: 180 }}
                                />
                                <ListItemText
                                    primary={articles?.numbers ? articles.numbers : 0}
                                    primaryTypographyProps={{
                                        fontSize: 13,
                                        color: "#021E40",
                                    }}
                                    sx={{ marginLeft: "1rem" }}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        backgroundColor: "#FBFBFB",
                        width: 300,
                        minHeight: 20,
                        marginLeft: isSmallScreen ? "" : "3rem",
                        marginTop: 5,
                        flexDirection: "column",
                        borderRadius: 2,
                        maxHeight: 200,
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)"
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#0057BA",
                            padding: "8px 16px 0px",
                            color: "white",
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5,

                        }}
                    >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <p className="font-bold " style={{ margin: 0, fontSize: 13 }}>TOP 5 VILLES LES PLUS CONSULTES</p>
                            <NewspaperIcon style={{ marginLeft: 15 }} />
                        </div>
                        <Divider sx={{ backgroundColor: "#fff", margin: "5px 0px 4px" }} />
                    </div>

                    {/* <p className="font-bold ">Top 5 Villes les plus consultés</p> */}

                    <Box
                        sx={{
                            overflowY: "scroll",
                            WebkitOverflowScrolling: "touch",
                            padding: 2,
                            "&::-webkit-scrollbar": {
                                width: "9px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#16324dc1",
                                borderRadius: "6px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "#999",
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#f5f5f5",
                                border: "0px solid #ccc",
                                borderRadius: "8px",
                            },
                        }}
                    >
                        {topCities.map((cities, cityIndex) => (
                            <Box key={cityIndex} sx={{ display: "flex" }}>
                                <ListItemText
                                    primary={cities?.name ? cities.name : ""}
                                    primaryTypographyProps={{
                                        fontSize: 13,
                                        fontWeight: 600,
                                    }}
                                    sx={{ width: 120 }}
                                />
                                <ListItemText
                                    primary={cities?.numbers ? cities.numbers : 0}
                                    primaryTypographyProps={{
                                        fontSize: 13,
                                    }}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        backgroundColor: "#FBFBFB",
                        width: 300,
                        minHeight: 20,
                        marginLeft: isSmallScreen ? "" : "3rem",
                        marginTop: 5,
                        // padding: 2,
                        flexDirection: "column",
                        borderRadius: 2,
                        maxHeight: 200,
                        // overflowY: "scroll",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)"
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#0057BA",
                            padding: "8px 16px 0px",
                            color: "white",
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5,

                        }}
                    >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <p className="font-bold " style={{ margin: 0, fontSize: 12.5 }}>TOP 5 THEMES LES PLUS CONSULTES</p>
                            <NewspaperIcon style={{ marginLeft: 15 }} />
                        </div>
                        <Divider sx={{ backgroundColor: "#fff", margin: "5px 0px 4px" }} />
                    </div>

                    <Box
                        sx={{
                            overflowY: "scroll",
                            WebkitOverflowScrolling: "touch",
                            padding: 2,
                            "&::-webkit-scrollbar": {
                                width: "9px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#16324dc1",
                                borderRadius: "6px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "#999",
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#f5f5f5",
                                border: "0px solid #ccc",
                                borderRadius: "8px",
                            },
                        }}
                    >

                        {themeCounter.map((articles, articleIndex) => (
                            <Box key={articleIndex} sx={{ display: "flex" }}>
                                <ListItemText
                                    sx={{ flex: 1 }}
                                    primary={articles?.name ? articles.name : ""}
                                    primaryTypographyProps={{
                                        fontSize: 13,
                                        fontWeight: 600,
                                    }}

                                />
                                <ListItemText
                                    primary={articles?.numbers ? articles.numbers : 0}
                                    primaryTypographyProps={{
                                        fontSize: 13,
                                    }}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>

            <Box sx={{ display: isSmallScreen ? "block" : "flex" }}>
                <Box
                    sx={{
                        display: "flex",
                        backgroundColor: "#FBFBFB",
                        width: 300,
                        minHeight: 20,
                        marginLeft: isSmallScreen ? "" : "3rem",
                        marginTop: 5,
                        // padding: 2,
                        flexDirection: "column",
                        borderRadius: 2,
                        maxHeight: 200,
                        // overflowY: "scroll",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)"
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#0057BA",
                            padding: "8px 16px 0px",
                            color: "white",
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5,

                        }}
                    >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <p className="font-bold " style={{ margin: 0, fontSize: 13 }}>PUBLICATION PAR THEME</p>
                            <NewspaperIcon style={{ marginLeft: 15 }} />
                        </div>
                        <Divider sx={{ backgroundColor: "#fff", margin: "5px 0px 4px" }} />
                    </div>

                    <Box
                        sx={{
                            overflowY: "scroll",
                            WebkitOverflowScrolling: "touch",
                            padding: 2,
                            "&::-webkit-scrollbar": {
                                width: "9px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#16324dc1",
                                borderRadius: "6px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "#999",
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#f5f5f5",
                                border: "0px solid #ccc",
                                borderRadius: "8px",
                            },
                        }}
                    >
                        {artTheme.map((articles, articleIndex) => (
                            <Box key={articleIndex} sx={{ display: "flex" }}>
                                <ListItemText
                                    sx={{ flex: 1 }}
                                    primary={articles?.name ? articles.name : ""}
                                    primaryTypographyProps={{
                                        fontSize: 13,
                                        fontWeight: 600,
                                    }}
                                />
                                <ListItemText
                                    primary={articles?.articleCount ? articles.articleCount : 0}
                                    primaryTypographyProps={{
                                        fontSize: 13,
                                    }}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        backgroundColor: "#FBFBFB",
                        width: 300,
                        minHeight: 20,
                        marginLeft: isSmallScreen ? "" : "3rem",
                        marginTop: 5,
                        flexDirection: "column",
                        borderRadius: 2,
                        maxHeight: 200,
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)"
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#0057BA",
                            padding: "8px 16px 0px",
                            color: "white",
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5,

                        }}
                    >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <p className="font-bold " style={{ margin: 0, fontSize: 12 }}>FLOP 5 VILLES LES MOINS CONSULTES</p>
                            <NewspaperIcon style={{ marginLeft: 15 }} />
                        </div>
                        <Divider sx={{ backgroundColor: "#fff", margin: "5px 0px 4px" }} />
                    </div>
                    <Box
                        sx={{
                            overflowY: "scroll",
                            WebkitOverflowScrolling: "touch",
                            padding: 2,
                            "&::-webkit-scrollbar": {
                                width: "9px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#16324dc1",
                                borderRadius: "6px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "#999",
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#f5f5f5",
                                border: "0px solid #ccc",
                                borderRadius: "8px",
                            },
                        }}
                    >

                        {flopCities.map((cities, cityIndex) => (
                            <Box key={cityIndex} sx={{ display: "flex" }}>
                                <ListItemText
                                    sx={{ flex: 1 }}
                                    primary={cities?.name ? cities.name : ""}
                                    primaryTypographyProps={{
                                        fontSize: 13,
                                        fontWeight: 600,
                                    }}
                                />
                                <ListItemText
                                    primary={cities?.numbers ? cities.numbers : 0}
                                    primaryTypographyProps={{
                                        fontSize: 13,
                                    }}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        backgroundColor: "#FBFBFB",
                        width: 300,
                        minHeight: 20,
                        marginLeft: isSmallScreen ? "" : "3rem",
                        marginTop: 5,
                        flexDirection: "column",
                        borderRadius: 2,
                        maxHeight: 200,
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)"
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#0057BA",
                            padding: "8px 16px 0px",
                            color: "white",
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5,

                        }}
                    >
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <p className="font-bold " style={{ margin: 0, fontSize: 11.9 }}>FLOP 5 THEMES LES MOINS CONSULTES</p>
                            <NewspaperIcon style={{ marginLeft: 15 }} />
                        </div>
                        <Divider sx={{ backgroundColor: "#fff", margin: "5px 0px 4px" }} />
                    </div>
                    <Box
                        sx={{
                            overflowY: "scroll",
                            WebkitOverflowScrolling: "touch",
                            padding: 2,
                            "&::-webkit-scrollbar": {
                                width: "9px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#16324dc1",
                                borderRadius: "6px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "#999",
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#f5f5f5",
                                border: "0px solid #ccc",
                                borderRadius: "8px",
                            },
                        }}
                    >

                        {flopTheme.map((themes, themeIndex) => (
                            <Box key={themeIndex} sx={{ display: "flex" }}>
                                <ListItemText
                                    sx={{ flex: 1 }}
                                    primary={themes?.name ? themes.name : ""}
                                    primaryTypographyProps={{
                                        fontSize: 13,
                                        fontWeight: 600,
                                    }}
                                />
                                <ListItemText
                                    primary={themes?.numbers ? themes.numbers : 0}
                                    primaryTypographyProps={{
                                        fontSize: 13,
                                    }}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>

    )
}

export default AdminStats